.img-fluid:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}
/* Add these styles to your CSS */
.subscribe_button {
  /* Add your existing button styles here */

  /* Adding a transition for smooth animation */
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease;
}

.subscribe_button:hover {
  /* Changing background color and adding a shadow on hover */
  background-color: #d03e20;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  /* Adding a slight scale effect on hover */
  transform: scale(1.05);
}

/* Adding a smooth transition for text color */
.subscribe_button:hover span {
  color: white;
  transition: color 0.3s ease;
}

/* Changing the cursor to a pointer on hover */
.subscribe_button:hover {
  cursor: pointer;
}
