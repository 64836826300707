.expenseError {
  font-size: 10px;
}

.subExpense {
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.expense-wrapper:nth-child(even) {
  background: #f9f9f9;
}

.formInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.formCurrencyInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.formTextArea {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formInput:focus {
  border: 1px solid #ced4da;
}
.clinetExpense-wrapper {
  border: 1px solid lightgrey;
  padding: 1rem;
  margin: 1rem;
}
.card-body {
  margin: 0;
  padding: 0;
}

.fileUpload > input {
  display: none;
}
@media only screen and (max-width: 600px) {
  .content-wrapper {
    margin: 0;
    padding: 0;
  }
}

small {
  text-transform: uppercase;
}
